<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> Product List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="6">
								<CInput
									label=""
									placeholder="Nama / Kode Barang"
									v-model="filter"
								/>
							</CCol>
							<CCol md="6">
								<CButton size="sm" @click="getProduct" color="info"
									><CIcon name="cil-reload"
								/></CButton>
							</CCol>
						</CRow>

						<CRow
							v-if="filter != ''"
							class="mb-3"
							:key="p.id"
							v-for="p in products"
						>
							<div class="col-md-12">
								<h5 class="mb-0 font-weight-bold">
									{{ p.nama }} - {{ p.stock }}
								</h5>
								<div
									class="font-weight-bold"
									:key="i.size"
									v-for="i in groupProductItems(p.productItems)"
								>
									{{ size[i.size].nama + " / " + i.size }} -
									<span v-if="i.stock > 0" class="text-danger">{{
										i.stock
									}}</span>
									<span v-else class="text-danger">KOSONG</span>
								</div>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { size, debounce } from "@/plugins/utils";
export default {
	data() {
		return {
			size,
			products: [],
			products2: [],
			filter: "",
			loading: false,
		};
	},
	mounted() {
		this.getProduct();
	},
	watch: {
		filter(val) {
			this.setFilter(val);
		},
	},
	methods: {
		getProduct: debounce(async function () {
			this.loading = true;
			this.filter = "";
			const { data } = await this.$http.get("product", {
				params: {
					sort: "stock",
					order: "asc",
					stock: true,
					page: 1,
					per_page: 9000,
				},
			});
			this.products = data.data.data;
			this.products2 = data.data.data;
			this.loading = false;
		}, 600),
		groupProductItems(items) {
			let data = {};
			for (let i = 0; i < items.length; i++) {
				const el = items[i];
				if (data[el.size]) {
					data[el.size].stock += el.stock;
				} else {
					data[el.size] = {
						size: el.size,
						stock: el.stock,
					};
				}
			}
			return data;
		},
		setFilter(text) {
			let prod = JSON.parse(JSON.stringify(this.products2));
			let prod2 = [];

			for (let i = 0; i < prod.length; i++) {
				const el = prod[i];
				if (text != "") {
					if (el.nama.toLowerCase().includes(text.toLowerCase().trim())) {
						prod2.push({ ...prod[i] });
					}
				}
			}

			this.products = prod2;
		},
	},
};
</script>
